<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card class="cs-print">
      <div class="d-print-none d-flex justify-content-end align-items-center mr-1 mb-2 gap-4">
        <b-button @click="handlePrint" variant="primary">In phiếu</b-button>
        <span @click="handleClose" class="cursor-pointer" style="font-size:40px">&times;</span>
      </div>
      <div class="container cs-container">
        <div ref="documentPrint" id="documentPrint" class="h-full">
          <div class="row">
            <div class="col-2"></div>
            <div class="col-6 text-center font-weight-bold"></div>
            <div class="col-4 d-flex justify-content-end cs-text-bold">
              <span>Mã phiếu:</span>
              <span class="ml-1">{{ receiptData.pharma_receipt_code}}</span>
            </div>
          </div>
          <div class="text-center mt-2">
            <h2 class="text-uppercase">Phiếu thu</h2>
            <h4 v-if="receiptData.inventory_info" class="m-0">{{receiptData.inventory_info.name}}</h4>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <span>Họ tên:</span>
              <span class="ml-1 cs-text-bold">{{ receiptData.customer_name }}</span>
            </div>
            <div class="col-3">
              <span>Tuổi:</span>
              <span class="ml-1 cs-text-bold">{{ receiptData.customer_age }}</span>
            </div>
            <div class="col-3">
              <span>Giới tính:</span>
              <span class="ml-1 cs-text-bold">{{ receiptData.customer_gender===1?'Nam':'Nữ' }}</span>
            </div>
          </div>
          <div class>
            <span>Số điện thoại:</span>
            <span class="ml-1 cs-text-bold">{{ receiptData.customer_phone }}</span>
          </div>
          <div class>
            <span>Địa chỉ:</span>
            <span class="ml-1 cs-text-bold">{{ receiptData.customer_address }}</span>
          </div>
          <div class>
            <span>Nơi chỉ định:</span>
            <span class="ml-1 cs-text-bold">{{ receiptData.prescribed_clinic }}</span>
          </div>
          <b-overlay
            :show="false"
            rounded="sm"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
          >
            <b-table
              small
              striped
              bordered
              outlined
              class="cs-btable mt-2"
              responsive
              :items="medicines"
              :fields="tableColumns"
            >
              <template #cell(index)="data">
                <span class="text-capitalize">{{ data.index+1 }}</span>
              </template>
              <template #cell(unit_id)="data">
                <span class="text-capitalize">{{getUnit(data.item) }}</span>
              </template>
              <template #cell(price)="data">
                <span class="text-capitalize">{{appUtils.numberFormat(data.item.realPrice) }}</span>
              </template>
              <template #cell(total_price)="data">
                <span class="text-capitalize">{{appUtils.numberFormat(data.item.total_price) }}</span>
              </template>
              <template #bottom-row>
                <b-th colspan="6" class="text-right" style="font-weight:500">Tổng tiền</b-th>
                <b-th
                  class="text-right"
                  style="font-weight:500"
                >{{ appUtils.numberFormat(receiptData.totalPrice) }}</b-th>
              </template>
            </b-table>
          </b-overlay>
          <div class="cs-text-bold mt-3">
            <div>
              <span>- Tổng tiền:</span>
              <span
                v-if="receiptData.totalPrice"
                class="ml-1"
              >{{ formatPrice(receiptData.totalPrice) }}</span>
            </div>
            <div class="d-flex align-items-center">
              <span>- Tổng tiền bằng chữ:</span>
              <span v-if="receiptData.totalPrice" class="ml-1 cs-capitalize">
                <p class="m-0">{{ `${appUtils.numberToWords(receiptData.totalPrice)}` }}</p>
              </span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-4"></div>
            <div class="col-4"></div>
            <div class="col-4 text-center">
              <div class="italic">
                <i>{{ formatDate(receiptData.receipt_date) }}</i>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-4 text-center">
              <div>
                <div class="cs-text-bold">Người nhận</div>
                <div>
                  <i>(Ký, họ tên)</i>
                </div>
                <div
                  v-if="receiptData.customer_name"
                  style="margin-top:65px"
                  class="text-uppercase"
                >{{ receiptData.customer_name}}</div>
              </div>
            </div>
            <div class="col-4 text-center">
              <!-- <div>
                <div class="cs-text-bold">Người giao</div>
              </div>
              <div
                v-if="userLocal.name"
                class="text-uppercase"
                style="margin-top:95px"
              >{{ userLocal.name}}</div>-->
            </div>
            <div class="col-4 text-center">
              <div>
                <div class="cs-text-bold">Thu ngân</div>
                <div class="text-center">
                  <i>(Ký, họ tên)</i>
                </div>
                <div
                  class="text-uppercase"
                  v-if="receiptData.collect_user"
                  style="margin-top:65px"
                >{{ receiptData.collect_user.name}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import appUtils from "@/utils/appUtils";

const tableColumns = [
  { key: "index", label: "STT", sortable: false },
  {
    key: "product_info.name",
    label: "Tên thuốc",
    sortable: false
  },
  { key: "product_info.quality", label: "Hàm lượng", sortable: false },
  { key: "qty", label: "Số lượng", sortable: false, class: "text-center" },
  { key: "unit_id", label: "Đơn vị", sortable: false },
  {
    key: "price",
    label: "Đơn giá (đã VAT)",
    sortable: false,
    class: "text-right"
  },
  {
    key: "total_price",
    label: "Thành tiền",
    sortable: false,
    class: "text-right"
  }
];

export default {
  name: "ReceiptDetail",
  data() {
    return {
      receiptData: {},
      medicines: [],
      isLoading: false,
      tableColumns,
      appUtils
    };
  },
  computed: {
    userLocal() {
      return JSON.parse(localStorage.getItem("user"));
    }
  },
  watch: {
    $route() {
      this.handleViewDetail();
    }
  },
  async created() {
    await this.handleViewDetail();
  },
  methods: {
    async handleViewDetail() {
      if (!this.$route.params.id) return;

      try {
        const response = await this.$store.dispatch(
          "saling/getReceiptDetail",
          this.$route.params.id
        );
        this.medicines = (response.data?.pharma_receipt_details || [])?.map(
          item => {
            // const realPrice =
            //   item?.sale_price * (1 + item?.product_info?.vat_out / 100);
            const roundPrice = Math.round((item?.sale_price * 100) / 100);
            return {
              ...item,
              realPrice: roundPrice,
              total_price: (roundPrice || 0) * (item?.qty || 0)
            };
          }
        );

        const totalPrice = this.medicines?.reduce(
          (total, item) => total + (item?.total_price || 0),
          0
        );

        this.receiptData = { ...response.data, totalPrice };
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi lấy dữ liệu",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      }
    },
    formatDate(timestamp) {
      return window.moment(timestamp).format("HH:mm-DD/MM/YYYY");
    },
    handleClose() {
      this.$router.replace({ name: "ReceiptManager" });
    },
    formatPrice(price) {
      return appUtils.numberFormat(price, "VNĐ");
    },
    handlePrint() {
      window.print();
    },
    getUnit(data) {
      return data?.product_info?.unit_info?.name || "";
    }
  }
};
</script>

<style lang="scss" scoped>
.cs-container {
  position: relative;
  background-color: #fff;
  min-height: calc(100vh - 1px);
  max-width: 1000px;
  overflow: hidden;
}

.cs-container * {
  color: #000;
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;

  h2 {
    font-weight: 600;
    font-size: 26px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
  }
  h5 {
    font-size: 16px;
    font-weight: 600;
  }

  .cs-table {
    width: 1000px;
    margin-top: 16px;
    .w-6 {
      width: 6%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-15 {
      width: 15%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-8 {
      width: 8%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-10 {
      width: 10%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-12 {
      width: 12%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-13 {
      width: 13%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-16 {
      width: 16%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-18 {
      width: 18%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-20 {
      width: 20%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-90 {
      width: 85%;
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;
    }

    .table-item {
      border-top: unset;
    }

    .body-item {
      padding-top: 8px;
      padding-bottom: 8px;
      // border-right: 1px solid #787878;

      box-sizing: border-box;
    }
  }
  .cs-table * {
    font-size: 16px;
  }

  .border-t {
    border-top: 1px solid #787878;
  }
  .border-b {
    border-bottom: 1px solid #787878;
  }
  .border-l {
    border-left: 1px solid #787878;
  }
  .border-r {
    border-right: 1px solid #787878;
  }
  .cs-border {
    border: 1px solid #787878;
  }
  .cs-capitalize {
    text-transform: lowercase;

    p::first-letter {
      text-transform: capitalize;
    }
  }
}
.cs-relative {
  position: relative;
}

.cs-text-bold {
  font-weight: 600;
}

.cs-close {
  display: block;
  position: absolute;
  top: -50px;
  right: 40px;
  // height: 30px;
  // width: 30px;
  font-size: 40px;
  z-index: 100;
  cursor: pointer;
}

.cs-font * {
  font-size: 16px;

  h4 {
    font-size: 20px;
  }
}

.not-payment ::v-deep {
  background-color: rgb(243, 129, 8) !important;
  color: white !important;
}

.reject ::v-deep {
  color: white !important;
  background-color: red !important;
}

.payment ::v-deep {
  color: white !important;
  background-color: #17ad73 !important;
}

.status {
  padding: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 4px;
  font-size: 16px;
  user-select: none;
  width: 100%;
}

.select-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.menu-select {
  background-color: #fff;
  position: absolute;
  bottom: -130px;
  right: 0;
  z-index: 2;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.cs-menu-item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;

  &:hover {
    background-color: rgba(21, 76, 205, 0.1);
  }

  span {
    display: block;
    // width: 100%;
    // height: 100%;
  }
}

.cs-item-active {
  background-color: #20419b !important;
  color: white;
}
::v-deep .cs-btable * {
  color: #000;
  font-family: "Times New Roman", Times, serif;

  thead * {
    font-size: 20px;
    text-transform: none !important;
  }

  tbody {
    font-size: 20px;
  }
}
.cs-print {
  margin: 0 !important;
}

.cs-print * {
  font-size: 20px;
}

@media print {
  .cs-print {
    display: block !important;
    overflow: hidden;
    margin-top: 30px;

    .card-body {
      padding: 0 !important;
    }
  }
}
</style>